import { onUnmounted, onBeforeMount } from 'vue'
import i18n from '../i18n'

export function useLazyI18n(translations: any, componentName: string = translations.componentName) {
  const { locale, mergeLocaleMessage, t } = i18n.global

  onBeforeMount(async () => {
    try {
      Object.keys(translations).forEach((lang) => {
        mergeLocaleMessage(lang, { [componentName]: translations[lang] })
      })
    } catch (error) {
      console.error('Failed to load translations', error)
    }
  })

  onUnmounted(() => {
    Object.keys(locale.value).forEach((lang) => {
      mergeLocaleMessage(lang, { [componentName]: {} })
    })
  })

  return {
    $lt: (key, ...args) => t(`${componentName}.${key}`, ...args),
    locale: locale.value
  }
}
