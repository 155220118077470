import type { RouteLocationNormalized } from 'vue-router'
import { useServiceTemplatesStore } from '@/stores/serviceTemplates'
import { useGeolocationStore } from '@/stores/geolocation'
import { useSearchFiltersStore } from '@/stores/searchFilters'
import { useGapStore } from '@/stores/gap'
import { computed } from 'vue'
import { useIframeStore } from '@/stores/iframe'

export function useQueryParams(to: RouteLocationNormalized) {
  const serviceId = to.query.serviceId // xx
  const postalCode = to.query.postalCode // h1h1h1
  const latitude = to.query.latitude // xx.xx
  const longitude = to.query.longitude // xx.xx
  const fees = to.query.fees // 1 || 0
  const availabilities = to.query.availabilities // t07 || ta7 || (-1)
  const establishmentType = to.query.establishmentType // pharmacy,private,public
  const gapMode = to.query.gapMode
  const placeTags = to.query['placeTags[]']

  const canPerformSearch = computed(() => {
    return !!serviceId && (!!postalCode || !!(latitude && longitude))
  })

  const extractSearchParams = () => {
    // Get query param for iframe placeTags
    const iframeStore = useIframeStore()
    if (iframeStore.isIframe && placeTags) {
      iframeStore.setIframeData(placeTags)
    }

    if (serviceId) {
      const serviceTemplatesStore = useServiceTemplatesStore()
      serviceTemplatesStore.currentServiceTemplate = serviceTemplatesStore.serviceTemplatesFindBy(
        'serviceTemplateId',
        Number(serviceId)
      )
    }

    if (latitude && longitude) {
      const geolocationStore = useGeolocationStore()
      geolocationStore.latitude = Number(latitude)
      geolocationStore.longitude = Number(longitude)
    } else if (postalCode) {
      const geolocation = useGeolocationStore()
      geolocation.postalCode = postalCode.trim().toLowerCase()
    }

    if (fees) {
      const searchFiltersStore = useSearchFiltersStore()
      if (fees == '0') {
        searchFiltersStore.withoutFeesFilter = true
        searchFiltersStore.mobile.withoutFeesFilter = true
      } else if (fees == '1') {
        searchFiltersStore.withFeesFilter = true
        searchFiltersStore.mobile.withFeesFilter = true
      }
    }

    if (availabilities) {
      const searchFiltersStore = useSearchFiltersStore()
      searchFiltersStore.withoutAvailsFilter = availabilities === '-1'
      searchFiltersStore.withT07AvailsFilter = availabilities === 't07'
      searchFiltersStore.withTa7AvailsFilter = availabilities === 'ta7'

      searchFiltersStore.mobile.withoutAvailsFilter = availabilities === '-1'
      searchFiltersStore.mobile.withT07AvailsFilter = availabilities === 't07'
      searchFiltersStore.mobile.withTa7AvailsFilter = availabilities === 'ta7'
    }

    if (establishmentType) {
      const searchFiltersStore = useSearchFiltersStore()
      const establishmentTypeToList = establishmentType.split(',')

      searchFiltersStore.withPharmacy = establishmentTypeToList.includes('pharmacy')
      searchFiltersStore.withPrivateClinic = establishmentTypeToList.includes('private')
      searchFiltersStore.withHealthEstablishment = establishmentTypeToList.includes('public')

      searchFiltersStore.mobile.withPharmacy = establishmentTypeToList.includes('pharmacy')
      searchFiltersStore.mobile.withPrivateClinic = establishmentTypeToList.includes('private')
      searchFiltersStore.mobile.withHealthEstablishment = establishmentTypeToList.includes('public')
    }

    useGapStore().gapMode = !!Number(gapMode)
  }

  return {
    extractSearchParams,
    canPerformSearch
  }
}
