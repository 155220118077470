'use strict'
import type { APIv3 } from '@/wrapper/interfaces/apiv3.interface'

export default (API: APIv3) => ({
  getServices: ({ placeId, establishmentId, serviceUnified, gapMode, gapHeadOfficeId }) => {
    const gapHeadOfficeIdParams =
      gapMode && gapHeadOfficeId ? `&gapHeadOfficeId=${gapHeadOfficeId}` : ''
    const urlV3 = `v3/establishments/${establishmentId}/places/${placeId}/services?service_unified=${serviceUnified}&gapMode=${gapMode}${gapHeadOfficeIdParams}`
    const requestV3 = API.http.get(urlV3)
    return API.handleError(requestV3)
  },
  getServiceTemplates: ({
    placeId,
    establishmentId,
    serviceTemplate,
    gapMode,
    gapHeadOfficeId
  }) => {
    const gapHeadOfficeIdParams =
      gapMode && gapHeadOfficeId ? `&gapHeadOfficeId=${gapHeadOfficeId}` : ''
    const urlV3 = `v3/establishments/${establishmentId}/places/${placeId}/serviceTemplates?serviceTemplate=${serviceTemplate}&gapMode=${gapMode}${gapHeadOfficeIdParams}`
    const requestV3 = API.http.get(urlV3)
    return API.handleError(requestV3)
  }
})
