import { createI18n } from 'vue-i18n'
import en from '@/lang/en'
import fr from '@/lang/fr'
import { LS_TRIMOZ_LANG } from '@/enums'

export const vueI18nOptions = {
  locale: localStorage.getItem(LS_TRIMOZ_LANG) || 'fr',
  fallbackLocale: 'en',
  warnHtmlMessage: false,
  legacy: false,
  messages: {
    fr,
    en
  }
}

export default createI18n({
  ...vueI18nOptions
})
