import { useAvailabilityFilter } from '@/components/panels/search/searchFilters/availabilityFilter/useAvailabilityFilter'
import { useEstablishmentTypeFilter } from '@/components/panels/search/searchFilters/establishmentTypeFilter/useEstablishmentTypeFilter'
import { usePricingFilter } from '@/components/panels/search/searchFilters/pricingFilter/usePricingFilter'
import get from 'lodash/get'
import reject from 'lodash/reject'
import includes from 'lodash/includes'

export const useFilters = () => {
  /**
   * Some initializations
   */
  const establishmentTypeFilter = useEstablishmentTypeFilter()
  const pricingFilter = usePricingFilter()
  const availabilityFilter = useAvailabilityFilter()

  /**
   * Applies filters on a place
   */
  const filterPlace = (place: any): boolean => {
    return establishmentTypeFilter.filter(place)
  }

  /**
   * Applies filters on the place's services
   */
  const filterPlaceServices = (services: any[]) => {
    return services.filter(
      (service) => pricingFilter.filter(service) && availabilityFilter.filter(service)
    )
  }

  const filteredServiceTemplatesCoAdmin = (
    servicesTemplates: any[],
    serviceTemplateIdsToExclude: any[]
  ) => {
    return reject(servicesTemplates, (service) =>
      includes(serviceTemplateIdsToExclude, get(service, 'serviceTemplateId', 0))
    )
  }

  return { filterPlace, filterPlaceServices, filteredServiceTemplatesCoAdmin }
}
