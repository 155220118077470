export default {
  componentName: 'SearchFilters',
  fr: {
    withWithoutFees: 'Avec et sans frais',
    withoutFees: 'Sans frais seulement',
    label: 'Tarification',
    placeholder: 'Tarification (Toutes)'
  },
  en: {
    withWithoutFees: 'With and without fees',
    withoutFees: 'Without fees only',
    label: 'Pricing',
    placeholder: 'Pricing (All)'
  }
}
