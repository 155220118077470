'use strict'
import type { APIv3 } from '@/wrapper/interfaces/apiv3.interface'

export default (API: APIv3) => ({
  getAll: () => {
    const urlV3 = 'v3/headOffices/establishments'
    const requestV3 = API.http.get(urlV3)
    return API.handleError(requestV3)
  }
})
