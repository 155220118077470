import { defineStore } from 'pinia'
import compact from 'lodash/compact'

export const useIframeStore = defineStore({
  id: 'iframe',
  state: () => ({
    isIframe: false,
    placesTagOnIframe: []
  }),
  actions: {
    setIframeData(payload: any) {
      if (typeof payload === 'string') {
        payload = [payload]
      }
      this.placesTagOnIframe = compact(payload)
    }
  }
})
