import { v4 as uuidv4 } from 'uuid'
import get from 'lodash/get'

export class TrimozError extends Error {
  constructor(previousError, textKey, internalCode = null, contextCode = null) {
    super(previousError.message)
    this.inner = previousError
    this.textKey = textKey
    this.uuid = uuidv4()
    this.internalCode = internalCode
    this.contextCode = contextCode
    this.httpStatus = get(previousError, 'response.status', null)
  }
}
