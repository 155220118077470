'use strict'
import type { APIv3 } from '@/wrapper/interfaces/apiv3.interface'
import map from 'lodash/map'
import isString from 'lodash/isString'

export default (API: APIv3) => ({
  getAll: () => {
    const url = `v3/portal/categories`
    const requestV3 = API.http.get(url)
    return API.handleError(requestV3)
  },
  getAllCategoriesWithTags: (placeTags?: Array<string>) => {
    if (isString(placeTags)) placeTags = `placeTags[]=${placeTags}`
    else if (!isString(placeTags) && placeTags?.length > 0)
      placeTags = `${map(placeTags, (tag) => `placeTags[]=${tag}`).join('&')}`

    const url = `v3/portal/categoriesWithTags?${placeTags}`
    const requestV3 = API.http.get(url)
    return API.handleError(requestV3)
  }
})
