import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faArrowLeft,
  faArrowRight,
  faAt,
  faBars,
  faBell,
  faCalendarDay,
  faCalendarMinus,
  faCaretDown,
  faCheckSquare,
  faChevronDown,
  faChevronUp,
  faCircle,
  faClinicMedical,
  faClock,
  faEnvelope,
  faExclamation,
  faExternalLinkAlt,
  faFilter,
  faGlobe,
  faHeart,
  faHome,
  faHospital,
  faLocationCrosshairs,
  faPlusSquare,
  faPowerOff,
  faRemove,
  faSearch,
  faMapMarker,
  faSignOutAlt,
  faStethoscope,
  faTicketAlt,
  faTimes,
  faUser,
  faUserCheck,
  faUserSecret,
  faWindowClose,
  faInfoCircle,
  faExclamationTriangle,
  faInfo,
  faCity,
  faSliders,
  faDollarSign,
  faPlus
} from '@fortawesome/free-solid-svg-icons'
import {
  faHeart as farHeart,
  faSquare,
  faCalendar,
  faCircle as farCircle,
  faDotCircle,
  faUser as farUser,
  faRectangleXmark
} from '@fortawesome/free-regular-svg-icons'

export default {
  install(app: any) {
    app.component('font-awesome-icon', FontAwesomeIcon)
    library.add(
      faFilter,
      faBars,
      faSignOutAlt,
      faCircle,
      faSearch,
      faCheckSquare,
      faTimes,
      faClock,
      faGlobe,
      faArrowLeft,
      faUserCheck,
      faUserSecret,
      faPlusSquare,
      faWindowClose,
      faUser,
      faHome,
      faCalendarMinus,
      faExternalLinkAlt,
      faHeart,
      faChevronUp,
      faChevronDown,
      faEnvelope,
      faHospital,
      faBell,
      faExclamation,
      faExclamationTriangle,
      faTicketAlt,
      faCalendarDay,
      faAt,
      faPowerOff,
      faCaretDown,
      faInfoCircle,
      faClinicMedical,
      faExclamationTriangle,
      faInfo,
      faStethoscope,
      faMapMarker,
      faRemove,
      faLocationCrosshairs,
      faCity,
      faSliders,
      faDollarSign,
      faArrowRight,
      faPlus
    )
    library.add(farHeart, faSquare, faCalendar, farCircle, faDotCircle, farUser, faRectangleXmark)
  }
}
