'use strict'
import noop from 'lodash/noop'
import type { APIv3 } from '@/wrapper/interfaces/apiv3.interface'

export default (API: APIv3) => ({
  getAccessToken: () => {
    const url = 'v3/auth/token'
    const request = API.http.post(url)
    return API.handleError(request)
  },

  getMyPatient: () => {
    const url = 'v3/resources/current'
    const request = API.http.get(url)
    return API.handleError(request)
  },

  getAllPatients: () => {
    const url = 'v3/profile/patients'
    const request = API.http.get(url)
    return API.handleError(request)
  },

  logout: () => {
    const request = API.http.post('v3/auth/logout').catch(() => {
      /**
       * Catch here so we don't want to stop the log out execution process.
       * Note:
       * - Error code 401 or 403 or error containing 'refresh token call has failed' are expected when token has been invalidated elsewhere.
       *   (e.g.: from the profile app)
       */
      return noop()
    })
    return API.handleError(request)
  }
})
