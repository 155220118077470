import { defineStore } from 'pinia'
import GA4 from 'google-analytics-ga4'
import upperFirst from 'lodash/upperFirst'
import toLower from 'lodash/toLower'
import i18n from '@/i18n'
import { LS_TRIMOZ_LANG } from '@/plugins/mf-event-bus'

export const useGlobalStore = defineStore({
  id: 'global',
  state: () => ({
    locale: localStorage.getItem(LS_TRIMOZ_LANG) || 'fr',
    style: '',
    staticFilesUrl: '',
    captchaKey: import.meta.env.VITE_APP_CAPTCHAKEY,
    theme: {},
    enableInteractiveMap: false,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    gapFlag: false,
    tagFlag: false,
    showCategoryDescriptionAlert: true,
    keyboardNavigationGuessed: false,
    routerHistory: { previous: null, current: null }
  }),
  actions: {
    setProduct(product: string) {
      this.style = product
      this.staticFilesUrl = `https://static.trimoz.com/${product}`
    },

    async setLocale(wantedLocale) {
      const lang = toLower(wantedLocale)
      try {
        if (lang) {
          i18n.global.locale.value = lang
          window.localStorage.setItem(LS_TRIMOZ_LANG, lang)
          this.locale = lang
        }
      } catch (e) {
        console.log(e)
      }
    },
    logEventGA(payload) {
      const [category, action, label] = payload
      if (category && action && label) {
        GA4.event({
          category: category,
          action: action,
          label: label
        })

        return true
      }
      return false
    }
  },
  getters: {
    invertLocale: (state) => (state.locale === 'fr' ? 'En' : 'Fr'),
    invertLocaleLong: (state) => (state.locale === 'fr' ? 'English' : 'Français'),
    isClicSante: (state) => state.style === 'clicsante',
    getTheme: (state) => state.theme,
    getEnableInteractiveMap: (state) => state.enableInteractiveMap,
    upperLocale: (state): string => upperFirst(state.locale),
    getLogoUrl: (state) => {
      return `${state.staticFilesUrl}/images/logo-clicsante-header-v1.png`
    },
    getAlternateLogoUrl: (state) => {
      return `${state.staticFilesUrl}/images/logo_clicsante_header_noel.png`
    },
    isGapFlagOn: (state) => {
      return state.gapFlag
    },
    isTagFlagOn: (state) => {
      return state.tagFlag
    },
    getShowCategoryDescriptionAlert: (state) => {
      return state.showCategoryDescriptionAlert
    }
  }
})
