import { keycloakInterceptorInstance } from '@/wrapper/interceptors/keycloak'
import logOutObservableWrapper from '@/mf/observables/logout.observable.wrapper'
import langObservableWrapper from '@/mf/observables/lang.observable.wrapper'
import homeObservableWrapper from '@/mf/observables/home.observable.wrapper'
import tokenObservableWrapper from '@/mf/observables/token.observable.wrapper'
import notFoundObservableWrapper from '@/mf/observables/not-found.observable.wrapper'

export const LS_TRIMOZ_LANG = 'tmz_lang'

export default {
  install(app: any, { router }) {
    langObservableWrapper.init({ isMFMode: app.config.globalProperties.$mfRemoteMode })
    homeObservableWrapper.setRouter(router)
    homeObservableWrapper.init({ isMFMode: app.config.globalProperties.$mfRemoteMode })
    notFoundObservableWrapper.init({ isMFMode: app.config.globalProperties.$mfRemoteMode })
    logOutObservableWrapper.init({ isMFMode: app.config.globalProperties.$mfRemoteMode })
    tokenObservableWrapper.init({
      isMFMode: app.config.globalProperties.$mfRemoteMode,
      callback: async () => {
        try {
          await keycloakInterceptorInstance.isTokenValidOrRefresh()
          tokenObservableWrapper.publish({ data: keycloakInterceptorInstance.keycloak.token })
        } catch (e) {
          await keycloakInterceptorInstance.keycloak.login()
        }
      }
    })

    handleLocalSetItem()
  }
}

function handleLocalSetItem() {
  const originalSetItem = localStorage.setItem
  localStorage.setItem = function (key, value) {
    if (value && window.localStorage.getItem(key) !== value) {
      if (key === LS_TRIMOZ_LANG) {
        langObservableWrapper.publish({ data: value })
      }
    }

    originalSetItem.apply(this, [key, value]) // Call the original setItem method
  }
}
