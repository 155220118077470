import { defineStore } from 'pinia'
import { TrimozError } from '@/trimoz-vue-error-handling/trimoz-error'
import i18n from '@/i18n'
import { captureException } from '@sentry/vue'

// Validate if project i18n supports error context code
function isContextCodeSupported(error: Error) {
  const key = `errors.${error.contextCode}`
  return error.contextCode && i18n.global.t(key) !== key
}

// Validate if project i18n supports error internal code
function isInternalCodeSupported(error: Error) {
  const key = `errors.${error.internalCode}`
  return error.internalCode && i18n.global.t(key) !== key
}

// Validate if project i18n supports provided text key
function isTextKeySupported(error: Error) {
  const key = error.textKey
  return error.textKey && i18n.global.t(key) !== key
}

// Set proper i18n text key depending on the support of error codes
function validateAndSetTextKeyI18n(error: Error) {
  if (isContextCodeSupported(error)) {
    error.textKey = `errors.${error.contextCode}`
  } else if (isInternalCodeSupported(error)) {
    error.textKey = `errors.${error.internalCode}`
  } else if (isTextKeySupported(error)) {
    // Nothing to do
  } else {
    error.textKey = 'errors.generic'
  }
}

export const useErrorStore = defineStore({
  id: 'error',
  state: () => ({
    lastError: null
  }),
  getters: {
    getLastError: (state) => {
      return state.lastError
    }
  },
  actions: {
    commit(method, payload) {
      if (typeof this[method] !== 'function') {
        throw new Error(`Action '${method}' is not defined`)
      }
      this[method](payload)
    },

    SET_LAST_ERROR(error) {
      this.lastError = error
    },

    // handleError
    handleError(error: Error) {
      // Display error only if it is handled
      if (error instanceof TrimozError || (error.uuid && error.inner)) {
        validateAndSetTextKeyI18n(error)
        this.commit('SET_LAST_ERROR', error)
      }
      // Else log it to sentry
      else {
        captureException(error)
      }
    },

    /**
     * Allows the capture of an error without showing it on screen.
     *
     * @param {*} error must contain error to report
     */
    captureSilentError(error) {
      const localError = new TrimozError(error, 'silent-error')
      captureException(localError)
    }
  }
})
