'use strict'
import map from 'lodash/map'
import type { APIv3 } from '@/wrapper/interfaces/apiv3.interface'

export default (API: APIv3) => ({
  fetchServiceUnifiedWithTags: ({ placeTags = null, gapMode = false, gapHeadOfficeId = null }) => {
    placeTags = placeTags ? `&${map(placeTags, (tag) => `placeTags[]=${tag}`).join('&')}` : ''

    let params = { gapMode }
    if (gapMode && gapHeadOfficeId) params = { ...params, gapHeadOfficeId }

    const urlV3 = `v3/portal/serviceUnifiedWithTags?${new URLSearchParams(
      params
    ).toString()}${placeTags}`
    const requestV3 = API.http.get(urlV3)
    return API.handleError(requestV3)
  }
})
