'use strict'
import type { APIv3 } from '@/wrapper/interfaces/apiv3.interface'

export default (API: APIv3) => ({
  cancelGlobalAppointment: (params) => {
    const urlV3 = `v3/sendConfirmCancellationMessage`
    const requestV3 = API.http.post(urlV3, params)
    return API.handleError(requestV3)
  },
  sendAppointmentReminder: (params) => {
    const urlV3 = `v3/sendClientCouponLostMailFromPortal`
    const requestV3 = API.http.post(urlV3, params)
    return API.handleError(requestV3)
  }
})
