import type { ServiceTemplate } from '@/interfaces/serviceTemplate.interface'
import { removeAccents } from '@/utils'
import Fuse from 'fuse.js'

const defaultServiceKeys = [
  {
    name: 'titleFr',
    weight: 0.8,
    getFn: (service: ServiceTemplate) => removeAccents(service.titleFr.toLowerCase())
  },
  {
    name: 'titleEn',
    weight: 0.8
  },
  {
    name: 'keywordsFr',
    weight: 0.2,
    getFn: (service: ServiceTemplate) =>
      service.keywordsFr.map((text: string) => removeAccents(text.toLowerCase()))
  },
  {
    name: 'keywordsEn',
    weight: 0.2
  }
]

const updateMaxMatches = (result: any) => {
  if (!result.maxMatchesTitle && !result.maxMatchesKeywords) {
    const nbMatches: any = {}
    let maxMatchesTitle = 0
    let maxMatchesKeywords = 0
    result.matches?.forEach((match) => {
      if (match.key.id) {
        const key = match.key.id + '/' + match.idx
        if (nbMatches[key]) {
          nbMatches[key]++
        } else {
          nbMatches[key] = 1
        }
        if (match.idx === undefined) {
          maxMatchesTitle = Math.max(maxMatchesTitle, nbMatches[key])
        } else {
          maxMatchesKeywords = Math.max(maxMatchesKeywords, nbMatches[key])
        }
      }
    })
    result.maxMatchesTitle = maxMatchesTitle
    result.maxMatchesKeywords = maxMatchesKeywords
  }
}

const defaultFuseOptions = {
  includeScore: true,
  threshold: 0.2,
  keys: defaultServiceKeys,
  ignoreLocation: true,
  includeMatches: true,
  minMatchCharLength: 3,
  sortFn: (a, b) => {
    updateMaxMatches(a)
    updateMaxMatches(b)

    if (a.maxMatchesTitle !== b.maxMatchesTitle) {
      return b.maxMatchesTitle - a.maxMatchesTitle
    }

    if (a.maxMatchesKeywords !== b.maxMatchesKeywords) {
      return b.maxMatchesKeywords - a.maxMatchesKeywords
    }

    return a.score - b.score
  }
}
export const fuse = new Fuse([], defaultFuseOptions)
export default {
  install(app) {
    app.config.globalProperties.$fuse = fuse
  }
}

/**
 * Split the provided string by spaces (ignoring spaces within "quoted text") into an array of tokens.
 *
 * @param string
 *
 * @see https://stackoverflow.com/a/16261693/1265472
 *
 * @debt Depending on the outcome of https://github.com/github/codeql/issues/5964 we may end up needing to change
 *   this regex for performance reasons.
 */
export const tokeniseStringWithQuotesBySpaces = (string: string): string[] => {
  const tokens = string.match(/("[^"]*?"|[^"\s]+)+(?=\s*|\s*$)/g) ?? []
  return tokens.filter((token) => token.length > 2)
}
