import { keycloakInterceptorInstance } from '@/wrapper/interceptors/keycloak'
import {
  MFObservableRole,
  MFObservableType,
  ObservableWrapperAbstract
} from '@trimoz/trimoz-windowed-observable'

/**
 * Observable class for language
 */
class LogoutObservableWrapper extends ObservableWrapperAbstract {
  constructor() {
    super({
      id: 'LogOut',
      role: MFObservableRole.HOST,
      type: MFObservableType.SUBSCRIBER
    })
  }

  //======================
  // Module Federation ON
  //======================

  // Nothing to do
  protected dataHasBeenChangedWithMF(): void {
    keycloakInterceptorInstance.logout()
  }

  //======================
  // Module Federation OFF
  //======================

  // Nothing to do
  protected publishWithoutMF(): void {}

  // Nothing to do
  protected subscribeWithoutMF(): void {}
}

// Create and export the observable instance
const logOutObservableWrapper = new LogoutObservableWrapper()
export default logOutObservableWrapper
