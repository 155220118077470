import replace from 'lodash/replace'
import Url from 'url-parse'

export function inferApiUrl() {
  const parsed = new Url(import.meta.env.VITE_APP_API || window.location.href)
  parsed.set('host', replace(parsed.host, 'portal3.', 'api3.'))
  parsed.set('host', replace(parsed.host, 'portal.', 'api.'))
  parsed.set('host', replace(parsed.host, 'portal-dot-', 'api-dot-'))
  return parsed.protocol + '//' + parsed.host
}

export function inferPortalUrl() {
  const parsed = new Url(import.meta.env.VITE_APP_PORTAL_URL || window.location.href)
  parsed.set('hostname', replace(parsed.hostname, 'portal3.', 'portal3.'))
  parsed.set('hostname', replace(parsed.hostname, 'portal.', 'portal.'))
  parsed.set('hostname', replace(parsed.hostname, 'portal-dot-', 'portal-dot-'))
  parsed.set('pathname', '')
  return parsed.toString()
}

export function inferCookieDomain(domain = null) {
  let hostname = domain || import.meta.env.VITE_APP_COOKIE_DOMAIN || window.location.hostname
  hostname = replace(hostname, 'portal3.', '')
  hostname = replace(hostname, 'portal.', '')
  hostname = replace(hostname, /portal-dot-.*?\./, '')
  return hostname
}

export function inferConsentDomain() {
  let hostname = import.meta.env.VITE_APP_CONSENT_DOMAIN || window.location.hostname
  hostname = replace(hostname, 'portal3.', 'portal3.')
  hostname = replace(hostname, 'portal.', 'portal.')
  hostname = replace(hostname, 'portal-dot-', 'portal-dot-')
  return hostname
}

export function inferProfileUrl() {
  let hostname = window.location.host
  hostname = replace(hostname, 'portal3.', 'profile.')
  hostname = replace(hostname, 'portal.', 'profile.')
  hostname = replace(hostname, 'portal-dot-', 'profile-dot-')
  hostname = replace(hostname, '8082', '8084')
  return window.location.protocol + '//' + hostname
}
