import { defineStore } from 'pinia'

export const useGapStore = defineStore('gap', {
  state: () => ({
    gapMode: false,
    gapHeadOfficeId: null
  }),
  actions: {
    isGapModeOn() {
      return !!Number(this.gapMode)
    },
    getUrlParams() {
      return this.isGapModeOn()
        ? `&gapMode=${this.gapMode}&gapHeadOfficeId=${this.gapHeadOfficeId}`
        : ''
    }
  }
})
