'use strict'
import type { APIv3 } from '@/wrapper/interfaces/apiv3.interface'

export default (API: APIv3) => ({
  fetchServiceTemplates: () => {
    const urlV3 = `v3/portal/serviceTemplates`
    const requestV3 = API.http.get(urlV3)
    return API.handleError(requestV3)
  },
  fetchWithSlug: (slug: string) => {
    const urlV3 = `v3/portal/serviceTemplates/${slug}`
    const requestV3 = API.http.get(urlV3)
    return API.handleError(requestV3)
  }
})
