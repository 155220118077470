'use strict'
import type { APIv3 } from '@/wrapper/interfaces/apiv3.interface'

export default (API: APIv3) => ({
  getForServiceForZipcode: ({
    dateStart,
    dateStop,
    service = null,
    headOffice = null,
    placeTags = null,
    maxDistance = 50,
    postalCode = null,
    page = 0,
    gapMode = false,
    gapHeadOfficeId = null,
    placeSettings = null
  }) => {
    maxDistance = maxDistance ? `&maxDistance=${maxDistance}` : ''
    dateStart = `dateStart=${dateStart}`
    dateStop = `&dateStop=${dateStop}`
    headOffice = headOffice ? `&headOffice=${headOffice}` : ''
    placeTags = placeTags ? `&placeTags=${placeTags.join(',')}` : ''
    service = service ? `&serviceUnified=${service}` : ''
    postalCode = postalCode ? `&zipcode=${postalCode}` : ''
    page = `&page=${page}`
    gapHeadOfficeId = gapMode && gapHeadOfficeId ? `&gapHeadOfficeId=${gapHeadOfficeId}` : ''
    gapMode = `&gapMode=${gapMode}`
    placeSettings = placeSettings ? `&placeSettings=${placeSettings}` : ''

    const urlV3 = `/v3/availabilitiesByZipcode?${dateStart}${dateStop}${maxDistance}${service}${headOffice}${postalCode}${page}${placeTags}${gapMode}${gapHeadOfficeId}${placeSettings}`
    const requestV3 = API.http.get(urlV3)
    return API.handleError(requestV3)
  },
  getForServiceForGeolocalisation: ({
    dateStart,
    dateStop,
    serviceTemplate = null,
    headOffice = null,
    placeTags = null,
    maxDistance = 50,
    postalCode = null,
    latitude = null,
    longitude = null,
    page = 0,
    gapMode = false,
    gapHeadOfficeId = null,
    placeSettings = null
  }) => {
    maxDistance = maxDistance ? `&maxDistance=${maxDistance}` : ''
    dateStart = `dateStart=${dateStart}`
    dateStop = `&dateStop=${dateStop}`
    headOffice = headOffice ? `&headOffice=${headOffice}` : ''
    placeTags = placeTags ? `&placeTags=${placeTags.join(',')}` : ''
    serviceTemplate = serviceTemplate ? `&serviceTemplate=${serviceTemplate}` : ''
    postalCode = postalCode ? `&zipcode=${postalCode}` : ''
    latitude = latitude ? `&latitude=${latitude}` : ''
    longitude = longitude ? `&longitude=${longitude}` : ''
    page = `&page=${page}`
    gapHeadOfficeId = gapMode && gapHeadOfficeId ? `&gapHeadOfficeId=${gapHeadOfficeId}` : ''
    gapMode = `&gapMode=${gapMode}`
    placeSettings = placeSettings ? `&placeSettings=${placeSettings}` : ''

    const urlV3 = `/v3/availabilitiesByGeolocalisation?${dateStart}${dateStop}${maxDistance}${serviceTemplate}${headOffice}${postalCode}${latitude}${longitude}${page}${placeTags}${gapMode}${gapHeadOfficeId}${placeSettings}`
    const requestV3 = API.http.get(urlV3)
    return API.handleError(requestV3)
  }
})
