import {
  MFObservableRole,
  MFObservableType,
  ObservableWrapperAbstract
} from '@trimoz/trimoz-windowed-observable'
import type { Router } from 'vue-router'

/**
 * Observable class for language
 */
class HomeObservableWrapper extends ObservableWrapperAbstract {
  private router: Router

  constructor() {
    super({
      id: 'RouteToHome',
      role: MFObservableRole.HOST,
      type: MFObservableType.SUBSCRIBER
    })
  }

  //======================
  // Module Federation ON
  //======================

  protected async dataHasBeenChangedWithMF() {
    await this.router.push({ name: 'home' })
  }

  //======================
  // Module Federation OFF
  //======================

  // Nothing to do
  protected publishWithoutMF(): void {}

  // Nothing to do
  protected subscribeWithoutMF(): void {}

  public setRouter(router: Router) {
    this.router = router
  }
}

// Create and export the observable instance
const homeObservableWrapper = new HomeObservableWrapper()
export default homeObservableWrapper
