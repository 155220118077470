import Keycloak from 'keycloak-js'
import { sanitizeUrl } from '@/wrapper/interceptors/keycloak/helpers'
import { DateTime } from 'luxon'

const VUE_APP_KEYCLOAK_ACCESS_TOKEN_MIN_VALIDITY_SECONDS = 30
// MF hack => 1500000 is arbitrary : we have to set a bigger value than
// the token lifetime to force it to be refreshed
const VUE_APP_KEYCLOAK_ACCESS_TOKEN_MIN_VALIDITY_SECONDS_FORCE_REFRESH = 1500000

export default class KeycloakInterceptor {
  keycloak: Keycloak
  isKcInstanceError: boolean
  isKeycloakInitialized: boolean
  mfRemoteMode: boolean
  keycloakServerUrl: string

  constructor() {
    this.isKcInstanceError = false

    this.onRequestOnFulfilledFailCallback = () => null

    this.realmAuth = import.meta.env.VITE_APP_KEYCLOAK_REALM_AUTH

    this.keycloakServerUrl = sanitizeUrl(import.meta.env.VITE_APP_KEYCLOAK_URL_AUTH)

    this.keycloakClientId = import.meta.env.VITE_APP_KEYCLOAK_CLIENT_ID_AUTH

    this.logoutUrlRedirect = sanitizeUrl(import.meta.env.VITE_APP_KEYCLOAK_LOGOUT_REDIRECT_URI)

    this.registerUrlRedirect = sanitizeUrl(import.meta.env.VITE_APP_KEYCLOAK_REGISTER_REDIRECT_URI)

    this.keycloak = new Keycloak({
      realm: this.realmAuth,
      url: this.keycloakServerUrl,
      clientId: this.keycloakClientId
    })

    this.keycloak.onAuthRefreshSuccess = () => {
      this.setAuthTsCookie()
    }

    this.keycloak.onAuthSuccess = () => {
      this.setAuthTsCookie()
    }

    this.keycloak.onAuthRefreshError = () => {
      this.deleteAuthTsCookie()
    }
  }

  init = async (initOptions = {}) => {
    try {
      const options = {
        onLoad: initOptions.onLoad || 'login-required',
        checkLoginIframe: initOptions.checkLoginIframe || false,
        redirectUri: sanitizeUrl(window.location.href)
      }

      if (options.checkLoginIframe) {
        options.silentCheckSsoRedirectUri =
          window.location.protocol + '//' + window.location.host + '/silent-check-sso.html'
      }

      const isUserAuth = await this.keycloak.init(options)
      if (isUserAuth) {
        return this.keycloak.loadUserInfo()
      }
      return false
    } catch (e) {
      console.log(e)
    }
  }

  requestOnFulfilled = async (config) => {
    if (this.isKcInstanceError || !this.validateCookieTs(document.cookie)) {
      return this.setPublicHeaders(config)
    }

    const validateOrRefreshToken = await this.isTokenValidOrRefresh()
    if (validateOrRefreshToken) {
      return this.setAuthHeaders(config)
    }
    this.onRequestOnFulfilledFailCallback()
    return this.setPublicHeaders(config)
  }

  requestOnRejected = (error) => {
    return error
  }

  isTokenValidOrRefresh = async (forceRefreshToken: boolean = false) => {
    if (!this.keycloak.token || !this.keycloak.authenticated) return false
    if (!forceRefreshToken && this.isAuthenticated()) return true

    if (forceRefreshToken) {
      await this.keycloak.updateToken(
        VUE_APP_KEYCLOAK_ACCESS_TOKEN_MIN_VALIDITY_SECONDS_FORCE_REFRESH
      )
    } else {
      await this.keycloak.updateToken(VUE_APP_KEYCLOAK_ACCESS_TOKEN_MIN_VALIDITY_SECONDS)
    }
    return true
  }

  isAuthenticated = () => {
    return (
      this.keycloak.authenticated &&
      !this.keycloak.isTokenExpired(VUE_APP_KEYCLOAK_ACCESS_TOKEN_MIN_VALIDITY_SECONDS)
    )
  }

  setAuthHeaders = (config) => {
    if (!config.headers) config.headers = {}
    config.headers['X-TRIMOZ-ACCESS-TOKEN'] = 'Bearer ' + this.keycloak.token
    config.headers['X-TRIMOZ-AUTH-REALM'] = this.realmAuth
    return config
  }

  setPublicHeaders = (config) => {
    if (!config.headers) config.headers = {}
    config.headers['X-TRIMOZ-ACCESS-TOKEN'] = 'public'
    return config
  }

  login = (currentFullPath?: string, locale?: string = 'fr') => {
    if (this.keycloak.authenticated) return
    return this.keycloak.login({
      redirectUri: sanitizeUrl(
        import.meta.env.VITE_APP_KEYCLOAK_LOGIN_REDIRECT_URI + (currentFullPath ?? '')
      ),
      locale
    })
  }

  logout = async (establishmentId = '', currentFullPath?: string, locale?: string = 'fr') => {
    this.deleteAuthTsCookie()
    const fullPath = currentFullPath ? `${currentFullPath}` : ''
    await this.keycloak.logout({
      redirectUri: sanitizeUrl(
        import.meta.env.VITE_APP_KEYCLOAK_LOGOUT_REDIRECT_URI +
          (establishmentId ? `/${establishmentId}` : '') +
          fullPath
      ),
      locale
    })
    return true
  }

  register = (establishmentId) => {
    if (this.keycloak.authenticated) return
    return this.keycloak.register({
      redirectUri: this.registerUrlRedirect + (establishmentId || '')
    })
  }

  createKeycloakServerUrl({ redirectUri, locale, isRegister }) {
    const baseUrl =
      this.keycloakServerUrl + '/realms/' + this.realmAuth + '/protocol/openid-connect/'
    let url =
      baseUrl +
      'auth' +
      '?client_id=' +
      this.keycloakClientId +
      '&redirect_uri=' +
      redirectUri +
      '&response_type=code' +
      '&scope=openid' +
      '&ui_locales=' +
      locale

    if (isRegister) {
      url += '&redirectToRegister=true'
    }
    return url
  }

  isTokenTsValid = (tokenExpiresInTs) => {
    if (!tokenExpiresInTs || isNaN(+tokenExpiresInTs)) return false
    return DateTime.fromSeconds(+tokenExpiresInTs) > DateTime.now()
  }

  convertTsToCookieTime = (ts) => {
    return DateTime.fromSeconds(ts).toUTC().toFormat("EEE, dd MMM yyyy HH:mm:ss 'UTC'")
  }

  setAuthTsCookie = () => {
    if (!this.keycloak.refreshTokenParsed || !this.keycloak.refreshTokenParsed.exp) return
    document.cookie = this.getCookieValue()
  }

  deleteAuthTsCookie() {
    document.cookie = this.getCookieValue(true)
  }

  hasCookieTs = (cookie) => {
    const cookies = cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()

      if (cookie.startsWith('reg_ts' + '=')) {
        return cookie
      }
    }
    return null
  }

  validateCookieTs = (cookie) => {
    const tsCookie = this.hasCookieTs(cookie)
    if (tsCookie) {
      return this.isTokenTsValid(+tsCookie.substring('reg_ts'.length + 1))
    }
    return false
  }

  getCookieValue(isExpired = false) {
    if (isExpired) {
      return `reg_ts=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${
        import.meta.env.VITE_APP_ENV === 'production' ? '.clicsante.ca' : 'localhost'
      }; path=/`
    }

    if (!this.keycloak.refreshTokenParsed || !this.keycloak.refreshTokenParsed.exp) {
      return ''
    }
    return `reg_ts=${this.keycloak.refreshTokenParsed.exp}; expires=${this.convertTsToCookieTime(
      +this.keycloak.refreshTokenParsed.exp
    )}; domain=${
      import.meta.env.VITE_APP_ENV === 'production' ? '.clicsante.ca' : 'localhost'
    }; path=/`
  }
}

let keycloakInterceptorInstance: any = null
const isUsingKc = import.meta.env.VITE_APP_IS_USING_KEYCLOAK_LOGIN_AUTH === 'true'
if (isUsingKc) {
  keycloakInterceptorInstance = new KeycloakInterceptor()
}

export { keycloakInterceptorInstance, isUsingKc }
