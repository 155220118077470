import {
  MFObservableRole,
  MFObservableType,
  ObservableWrapperAbstract
} from '@trimoz/trimoz-windowed-observable'

/**
 * Observable class for language
 */
class NotFoundObservableWrapper extends ObservableWrapperAbstract {
  constructor() {
    super({
      id: 'RouteToNotFound',
      role: MFObservableRole.HOST,
      type: MFObservableType.SUBSCRIBER
    })
  }

  //======================
  // Module Federation ON
  //======================

  protected async dataHasBeenChangedWithMF() {
    window.location.replace(window.location.origin + '/not-found')
  }

  //======================
  // Module Federation OFF
  //======================

  // Nothing to do
  protected publishWithoutMF(): void {}

  // Nothing to do
  protected subscribeWithoutMF(): void {}
}

// Create and export the observable instance
const notFoundObservableWrapper = new NotFoundObservableWrapper()
export default notFoundObservableWrapper
