import { defineStore } from 'pinia'

export const useLoadingStore = defineStore('loading', {
  state: () => ({
    isLoading: false,
    message: 'Loading...please wait'
  }),
  actions: {
    toggleLoading(payload: any) {
      this.isLoading = payload.set
      this.message = payload.message
    }
  }
})
