import * as Sentry from '@sentry/vue'

export default {
  install(app, options: any) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENV,
      attachStacktrace: true,
      debug: /true/.test(import.meta.env.VITE_SENTRY_SHOW_DEBUG),
      // Set tracesSampleRate to 1.0 to capture 100% for DEV Or Staging
      tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 0.1,
      denyUrls: [import.meta.env.VITE_APP_KEYCLOAK_URL_AUTH],
      ignoreErrors: ['status code 401', 'status code 403'],
      tracePropagationTargets: [
        import.meta.env.VITE_APP_KEYCLOAK_LOGIN_REDIRECT_URI,
        import.meta.env.VITE_APP_API,
        import.meta.env.VITE_APP_KEYCLOAK_URL_AUTH
      ],
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(options.router)
        })
      ],
      beforeSend(event: Event): Event | PromiseLike<Event | null> | null {
        if (event.type === 'csp') {
          return null
        }
        return event
      }
    })
    Sentry.configureScope((scope) => {
      scope.setTag('product', options.product)
    })
  }
}
