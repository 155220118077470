import { defineStore } from 'pinia'
import find from 'lodash/find'
import { inferProfileUrl } from '@/helpers/env'
import { useGlobalStore } from '@/stores/global'
import { api } from '@/plugins/api'
import { signInWindow } from '@/helpers/oauthWindow'
import { hasRtkpCookie } from '@/utils'

export const useJwtStore = defineStore({
  id: 'jwt',
  state: () => ({
    accessToken: null,
    isInRetryMode: false,
    queuedRequest: [],
    headRequest: undefined,
    loggedUser: undefined,
    patients: [],
    loading: false,
    logoutConfirmationModal: false
  }),
  getters: {
    getAccessToken(state) {
      return state.accessToken
    },

    getIsInRetryMode(state) {
      return state.isInRetryMode
    },

    getHeadRequest(state) {
      return state.headRequest
    },

    getProfileUrl() {
      return inferProfileUrl()
    },

    getOwnerPatient(state): any {
      const owner = find(state.patients, { associationType: 'owner' })
      return owner || {}
    },
    getLoading(state) {
      return state.loading
    },
    getLocale() {
      return useGlobalStore().locale
    },
    getLogoutConfirmationModal(state) {
      return state.logoutConfirmationModal
    }
  },
  actions: {
    commit(method, payload) {
      if (typeof this[method] !== 'function') {
        throw new Error(`Action '${method}' is not defined`)
      }
      this[method](payload)
    },

    SET_ACCESS_TOKEN(token) {
      this.accessToken = token
    },

    CLEAR_ACCESS_TOKEN() {
      this.accessToken = null
    },

    SET_IS_IN_RETRY_MODE(value) {
      this.isInRetryMode = value
    },

    ENQUEUE_REQUEST(req) {
      this.queuedRequest.push(req)
    },

    DEQUEUE_REQUEST() {
      this.headRequest = this.queuedRequest.shift()
    },

    SET_LOGGED_USER(user) {
      this.loggedUser = user
    },

    SET_PATIENTS(patients) {
      this.patients = patients
    },
    SET_LOADING(payload) {
      this.loading = payload
    },
    SET_LOGOUT_CONFIRMATION_MODAL(payload) {
      this.logoutConfirmationModal = payload
    },

    async fetchAccessToken() {
      if (hasRtkpCookie()) {
        const response = await api.auth.getAccessToken()
        this.commit('SET_ACCESS_TOKEN', response.data.accessToken)
      }
    },

    async logout() {
      this.commit('SET_LOADING', true)
      try {
        await api.auth.logout()
        this.commit('SET_ACCESS_TOKEN', null)
        this.commit('SET_LOGGED_USER', null)
        this.commit('SET_PATIENTS', [])
      } finally {
        this.commit('SET_LOADING', false)
      }
    },

    async getLoggedUser() {
      if (this.getAccessToken) {
        const response = await api.auth.getMyPatient()
        this.commit('SET_LOGGED_USER', response.data)
      }
    },

    async getPatients() {
      if (this.getAccessToken) {
        const response = await api.auth.getAllPatients()
        this.commit('SET_PATIENTS', response.data)
      }
    },

    async openSignInWindow() {
      await signInWindow(
        `${this.getProfileUrl}/oauth/login?baseUrl=${window.location.origin}&locale=${this.getLocale}`
      )
      try {
        this.commit('SET_LOADING', true)
        await this.fetchAccessToken()
        await this.getLoggedUser()
        await this.getPatients()
      } finally {
        this.commit('SET_LOADING', false)
      }
    },

    async openRegisterWindow() {
      await signInWindow(
        `${this.getProfileUrl}/oauth/register?baseUrl=${window.location.origin}&locale=${this.getLocale}`
      )
      await this.fetchAccessToken()
      await this.getLoggedUser()
      await this.getPatients()
    }
  }
})
