'use strict'
import type { APIv3 } from '@/wrapper/interfaces/apiv3.interface'

export default (API: APIv3) => ({
  addFavorite: (data) => {
    const url = `v3/profile/favorites/places`
    const requestV3 = API.http.post(url, data, {
      headers: { 'Content-Type': 'application/json' }
    })
    return API.handleError(requestV3)
  },
  deleteFavorite: (data) => {
    const url = `v3/profile/favorites/places`
    const requestV3 = API.http.delete(url, {
      headers: { 'Content-Type': 'application/json' },
      data
    })
    return API.handleError(requestV3)
  }
})
