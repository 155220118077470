import { createApp, h, Fragment } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'

import App from '@/App.vue'
import { initializeRouter } from '@/router'
import i18n from '@/i18n'
import vuetify from '@/vuetify'

// Font Awesome
import fontAwesomePlugin from '@/plugins/font-awesome'
import '@/assets/scss/app.scss'

import { getGoogleAnalytics4 } from '@/plugins/analytics'
import { createGtm } from '@gtm-support/vue-gtm'
import { setFavicon, setProduct } from '@/theme'

import GA4 from 'google-analytics-ga4'
import VueSkipTo from '@vue-a11y/skip-to'
import { useGlobalStore } from '@/stores/global'
import { vMaska } from 'maska'

import Api from './wrapper'
import { keycloakInterceptorInstance } from './wrapper/interceptors/keycloak'
import { useKcAuthStore } from '@/stores/kcAuth'
import { inferApiUrl } from '@/helpers/env'
import { useErrorStore } from '@/trimoz-vue-error-handling/error-store'

import { VueRecaptchaPlugin } from 'vue-recaptcha/head'

// Custom Scroll
import scrollPlugin from '@/plugins/scroll'
import fuse from '@/plugins/fuse'

// Sentry
import sentryPlugin from '@/plugins/sentry'
import { getGoogleTagManager } from '@/plugins/googleTagManager'

// MF
import mfEventBus from '@/plugins/mf-event-bus'

import langPlugin from '@/plugins/lang'
import { useIframeStore } from '@/stores/iframe'
import { isBotCheck } from '@/helpers/bot-detector'

let app = null

// a11y setup dev monitoring tool.
if (import.meta.env.VITE_APP_ENV === 'development') {
  const VueAxe = await import('vue-axe')
  app = createApp({
    render: () => h(Fragment, [h(App), h(VueAxe.VueAxePopup)])
  })
  app.use(VueAxe.default)
  // By default, the mfRemoteMode is set to false in dev
  app.config.globalProperties.$mfRemoteMode = import.meta.env.VITE_APP_IS_MF_ENABLED
} else {
  app = createApp(App)
  app.config.globalProperties.$mfRemoteMode = true
}

export const pinia = createPinia()
const head = createHead()
app.use(VueSkipTo)
app.use(scrollPlugin)
app.use(pinia)
app.use(head)
app.use(i18n)
app.use(vuetify)
app.use(fuse)

export const api = Api(
  inferApiUrl(),
  setProduct(window.location.hostname),
  app.config.globalProperties.$mfRemoteMode
)

const product = setProduct(window.location.hostname)

app.config.errorHandler = function (err) {
  if (import.meta.env.VITE_APP_ENV === 'development') {
    console.error(err)
  }
  useErrorStore().handleError(err)
}

// google Analytics 4
try {
  if (getGoogleAnalytics4() && !isBotCheck()) {
    GA4.initialize(getGoogleAnalytics4())
  }
} catch (e) {
  console.warn('Google Analytics 4 instance not initiated')
  console.error(e)
}

const isKcAuth = import.meta.env.VITE_APP_IS_USING_KEYCLOAK_LOGIN_AUTH === 'true'

useIframeStore().isIframe = window.location !== window.parent.location
;(async () => {
  let isUserAuth = false
  let isKeycloakInitError = false

  try {
    if (isKcAuth && !useIframeStore().isIframe && !isBotCheck()) {
      isUserAuth = await keycloakInterceptorInstance.init({
        redirectUri: window.location.href,
        onLoad: 'check-sso',
        checkLoginIframe: true
      })
    }
  } catch (e) {
    isKeycloakInitError = true
    console.warn('Keycloak init error')
    console.error(e)
    keycloakInterceptorInstance.isKcInstanceError = true
    keycloakInterceptorInstance.deleteAuthTsCookie()
  } finally {
    app.mixin({
      mounted() {
        document.dispatchEvent(new Event('x-app-rendered'))
      },
      created() {
        useKcAuthStore().isUsingKc = isKcAuth
        useGlobalStore().setProduct(product)
        setFavicon(product)

        if (isUserAuth) {
          useKcAuthStore().isUserLogged = true
        }

        if (isKeycloakInitError) {
          useKcAuthStore().isKcInitError = true
        }
      }
    })

    app.use(fontAwesomePlugin)
    app.mixin(langPlugin)
    app.directive('maska', vMaska)
    const router = initializeRouter()

    //router aware plugins
    if (!isBotCheck()) {
      app.use(sentryPlugin, {
        router,
        product
      })
    }

    app.use(VueRecaptchaPlugin, {
      v2SiteKey: import.meta.env.VITE_APP_CAPTCHAKEY,
      loaderOptions: {
        recaptchaApiURL: 'https://www.google.com/recaptcha/api.js',
        params: {
          hl: i18n.global.locale.value,
          render: 'explicit',
          async: true,
          defer: true
        }
      }
    })

    // Google Tag Manager
    try {
      if (!isBotCheck()) {
        app.use(
          createGtm({
            id: getGoogleTagManager(),
            vueRouter: router
          })
        )
      }
    } catch (e) {
      console.warn('Google Tags Manager instance not initiated')
      console.error(e)
    }

    app.use(router)
    app.use(mfEventBus, { router })

    app.mount('#app')
  }
})()
