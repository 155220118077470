import { defineStore } from 'pinia'
import { useGlobalStore } from '@/stores/global'
import { keycloakInterceptorInstance } from '@/wrapper/interceptors/keycloak'
import { getFullPathFromPathAndQueryParams } from '@/utils'

export const useKcAuthStore = defineStore({
  id: 'kcAuth',
  state: () => ({
    isUserLogged: false,
    isKcInitError: false,
    isUsingKc: false,
    logoutConfirmationModal: false
  }),
  actions: {
    setLogoutConfirmationModal(value) {
      this.logoutConfirmationModal = value
    },
    async logout(currentPath?: string, currentQueryParams?: []) {
      return keycloakInterceptorInstance.logout(
        '',
        getFullPathFromPathAndQueryParams(currentPath, currentQueryParams),
        useGlobalStore().locale
      )
    },

    async login(currentPath?: string, currentQueryParams?: []) {
      return keycloakInterceptorInstance.login(
        getFullPathFromPathAndQueryParams(currentPath, currentQueryParams),
        useGlobalStore().locale
      )
    },

    async register() {
      return keycloakInterceptorInstance.register()
    }
  }
})
