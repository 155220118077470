import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

export const useCategoriesStore = defineStore('categories', {
  state: () => ({
    // Categories
    suggestedCategories: [] as Category[],
    allCategories: [] as Category[],
    currentCategory: null as Category,
    loadingCategories: false as boolean
  }),
  actions: {
    async fetchCategories(placeTags?: Array<string>) {
      if (placeTags?.length) {
        const response = await api.category.getAllCategoriesWithTags(placeTags)
        this.allCategories = response.data
      } else {
        const response = await api.category.getAll()
        this.allCategories = response.data
      }

      this.suggestedCategories = this.allCategories.filter(
        (category: Category) => category.weight !== null
      )
    }
  }
})
