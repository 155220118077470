'use strict'
import type { APIv3 } from '@/wrapper/interfaces/apiv3.interface'

export default (API: APIv3) => ({
  getGlobalSettings: () => {
    const url = 'v3/globalSettings'
    const request = API.http.get(url)
    return API.handleError(request)
  }
})
