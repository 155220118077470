import { useSearchFiltersStore } from '@/stores/searchFilters'
import { ServicesFiltersEnum } from '@/enums'
import { useLazyI18n } from '@/plugins/lazy-i18n'
import { computed } from 'vue'
import includes from 'lodash/includes'
import translations from './Lang'
import { useGoogleAnalyticsHelpers } from '@/composables/googleAnalytics/GoogleAnalyticsHelpers'

export function useAvailabilityFilter(isMobile = false) {
  /**
   * Some initializations
   */
  const { $lt } = useLazyI18n(translations)
  const searchFiltersStore = useSearchFiltersStore()
  const { portalVisibilityFilterAnalytics } = useGoogleAnalyticsHelpers()

  /**
   * [Computed] Traductions
   */
  const lang = computed(() => ({
    label: $lt('label'),
    placeholder: $lt('placeholder'),
    showAllResultsAvails: $lt('all'),
    showResultsWithT07Avails: $lt('showResultsWithT07Avails'),
    showResultsWithTa7Avails: $lt('showResultsWithTa7Avails'),
    showResultsWithNoAvails: $lt('showResultsWithNoAvails')
  }))

  /**
   * [Computed] Returns the list of filters
   * @description : Has TU
   */
  const filtersList = computed(() => [
    {
      id: ServicesFiltersEnum.WITH_WITHOUT_AVAILS,
      title: lang.value.showAllResultsAvails
    },
    {
      id: ServicesFiltersEnum.WITH_T07_AVAILS,
      title: lang.value.showResultsWithT07Avails
    },
    {
      id: ServicesFiltersEnum.WITHOUT_AVAILS,
      title: lang.value.showResultsWithNoAvails
    }
  ])

  /**
   * [Computed] Returns the active filter
   * @description : Has TU
   */
  const activeFilter = computed((): any => {
    if (isMobile) {
      if (
        searchFiltersStore.mobile.withT07AvailsFilter &&
        searchFiltersStore.mobile.withTa7AvailsFilter
      )
        return ServicesFiltersEnum.WITH_WITHOUT_AVAILS
      if (searchFiltersStore.mobile.withoutAvailsFilter) return ServicesFiltersEnum.WITHOUT_AVAILS
      if (searchFiltersStore.mobile.withT07AvailsFilter) return ServicesFiltersEnum.WITH_T07_AVAILS
      if (searchFiltersStore.mobile.withTa7AvailsFilter) return ServicesFiltersEnum.WITH_TA7_AVAILS
    } else {
      if (searchFiltersStore.withT07AvailsFilter && searchFiltersStore.withTa7AvailsFilter)
        return ServicesFiltersEnum.WITH_WITHOUT_AVAILS
      if (searchFiltersStore.withoutAvailsFilter) return ServicesFiltersEnum.WITHOUT_AVAILS
      if (searchFiltersStore.withT07AvailsFilter) return ServicesFiltersEnum.WITH_T07_AVAILS
      if (searchFiltersStore.withTa7AvailsFilter) return ServicesFiltersEnum.WITH_TA7_AVAILS
    }
    return undefined
  })

  /**
   * [Computed] Returns the current value (web mode)
   */
  const currentValue = computed({
    get: () => {
      return filtersList.value.filter((item) => {
        if (includes(activeFilter.value, item.id)) return item
      })[0]
    },
    set: (value) => {
      setAvailabilityFilter(value)
      portalVisibilityFilterAnalytics(
        'availability',
        currentValue.value ? currentValue.value.id : null
      )
    }
  })

  /**
   * [Computed] Updates the right filter state
   * @description : Has TU
   */
  const setAvailabilityFilter = (item?: any) => {
    const withoutAvailsFilter = item?.id === ServicesFiltersEnum.WITHOUT_AVAILS
    const withT07AvailsFilter =
      item?.id === ServicesFiltersEnum.WITH_T07_AVAILS ||
      item?.id === ServicesFiltersEnum.WITH_WITHOUT_AVAILS
    const withTa7AvailsFilter =
      item?.id === ServicesFiltersEnum.WITH_TA7_AVAILS ||
      item?.id === ServicesFiltersEnum.WITH_WITHOUT_AVAILS

    if (!isMobile) {
      searchFiltersStore.withoutAvailsFilter = withoutAvailsFilter
      searchFiltersStore.withT07AvailsFilter = withT07AvailsFilter
      searchFiltersStore.withTa7AvailsFilter = withTa7AvailsFilter
    }
    searchFiltersStore.mobile.withoutAvailsFilter = withoutAvailsFilter
    searchFiltersStore.mobile.withT07AvailsFilter = withT07AvailsFilter
    searchFiltersStore.mobile.withTa7AvailsFilter = withTa7AvailsFilter
  }

  /**
   * [Computed] Clears all filters
   * @description : Has TU
   */
  const clearFilters = () => {
    searchFiltersStore.withoutAvailsFilter = false
    searchFiltersStore.withT07AvailsFilter = false
    searchFiltersStore.withTa7AvailsFilter = false
  }

  /**
   * [Computed] Applies all mobile filters to main filters
   * @description : Has TU
   */
  const applyMobileFilters = () => {
    searchFiltersStore.withoutAvailsFilter = searchFiltersStore.mobile.withoutAvailsFilter
    searchFiltersStore.withT07AvailsFilter = searchFiltersStore.mobile.withT07AvailsFilter
    searchFiltersStore.withTa7AvailsFilter = searchFiltersStore.mobile.withTa7AvailsFilter
  }

  /**
   * [Computed] Cancels all mobile filters by main filters
   * @description : Has TU
   */
  const cancelMobileFilters = () => {
    searchFiltersStore.mobile.withoutAvailsFilter = searchFiltersStore.withoutAvailsFilter
    searchFiltersStore.mobile.withT07AvailsFilter = searchFiltersStore.withT07AvailsFilter
    searchFiltersStore.mobile.withTa7AvailsFilter = searchFiltersStore.withTa7AvailsFilter
  }

  /**
   * [Computed] Clears all mobile filters (all are turned to false)
   * @description : Has TU
   */
  const clearMobileFilters = () => {
    searchFiltersStore.mobile.withoutAvailsFilter = false
    searchFiltersStore.mobile.withT07AvailsFilter = false
    searchFiltersStore.mobile.withTa7AvailsFilter = false
  }

  /**
   * [Function] Applies filter on a given service
   */
  const filter = (service: any): boolean => {
    const { t07, ta7 } = service.availabilities

    if (t07 === null && ta7 === null) return true

    if (!activeFilter.value || activeFilter.value === ServicesFiltersEnum.WITH_WITHOUT_AVAILS) {
      return t07 > 0 || ta7 > 0
    }

    if (activeFilter.value === ServicesFiltersEnum.WITH_T07_AVAILS) {
      return t07 > 0
    } else if (activeFilter.value === ServicesFiltersEnum.WITH_TA7_AVAILS) {
      return t07 === 0 && ta7 > 0
    } else if (activeFilter.value === ServicesFiltersEnum.WITHOUT_AVAILS) {
      return t07 === 0 && ta7 === 0
    }

    return false
  }

  return {
    lang,
    filtersList,
    activeFilter,
    currentValue,
    setAvailabilityFilter,
    clearFilters,
    applyMobileFilters,
    cancelMobileFilters,
    clearMobileFilters,
    filter
  }
}
