'use strict'

export default (APIV3) => ({
  geocoder: {
    getPosition: (zipcode) => {
      const urlV3 = `v3/geocoder${zipcode ? `?zipcode=${zipcode}` : ''}`
      const requestV3 = APIV3.http.get(urlV3)
      return APIV3.handleError(requestV3)
    }
  }
})
