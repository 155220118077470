function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}

export function scrollTo(target, options = {}) {
  const duration = options.duration || 1000
  const offset = options.offset || 0
  let start

  const element = typeof target === 'string' ? document.querySelector(target) : target

  if (!element) return
  const startingY = window.scrollY // Using window.scrollY instead of window.pageYOffset
  const diff = element.getBoundingClientRect().top + offset

  const animation = (timestamp) => {
    if (!start) start = timestamp
    const time = Math.min(1, (timestamp - start) / duration)
    const easedTime = easeInOutCubic(time)

    window.scrollTo(0, startingY + diff * easedTime)

    if (time < 1) requestAnimationFrame(animation)
  }

  requestAnimationFrame(animation)
}

export default {
  install(app) {
    app.config.globalProperties.$scrollTo = (target, options = {}) => {
      const duration = options.duration || 1000
      const offset = options.offset || 0

      if (typeof target === 'string') {
        const element = document.querySelector(target)
        if (element) {
          const elementY = window.pageYOffset + element.getBoundingClientRect().top
          scrollTo(elementY, duration, offset)
        }
      } else if (typeof target === 'number') {
        scrollTo(target, duration, offset)
      }
    }
  }
}
