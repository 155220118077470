import { useGoogleTagManager } from '@/composables/googleAnalytics/GoogleTagManager'
import { GtmVariablesEnum } from '@/composables/googleAnalytics/GoogleTagManager.enum'

export const useGoogleAnalyticsHelpers = () => {
  const { gtmPush } = useGoogleTagManager()

  const portalVisibilitySearchBarAnalytics = (analyticsParams) => {
    gtmPush('portalVisibilitySearchBar', analyticsParams)
  }

  const portalVisibilitySearchFromServicePageAnalytics = (analyticsParams) => {
    gtmPush('portalVisibilitySearchFromServicePage', analyticsParams)
  }

  const portalVisibilitySearchFromIFrameAnalytics = (analyticsParams) => {
    gtmPush('portalVisibilitySearchFromIFrame', analyticsParams)
  }

  const portalVisibilitySelectTileAnalytics = (id: string, nameFr: string) => {
    gtmPush('portalVisibilitySelectTile', {
      [GtmVariablesEnum.SERVICE_UNIFIED_ID]: id,
      [GtmVariablesEnum.SERVICE_UNIFIED_NAME_FR]: nameFr
    })
  }

  const portalVisibilitySelectServiceAnalytics = (id: number, nameFr: string) => {
    gtmPush('portalVisibilitySelectService', {
      [GtmVariablesEnum.SERVICE_TEMPLATE_ID]: id,
      [GtmVariablesEnum.SERVICE_TEMPLATE_NAME_FR]: nameFr
    })
  }

  const portalVisibilitySelectClinicAnalytics = (place, service) => {
    gtmPush('portalVisibilitySelectClinic', {
      portalSelectClinic: {
        distance: place.distance
      },
      [GtmVariablesEnum.SERVICE_TEMPLATE_ID]: service.stId,
      [GtmVariablesEnum.SERVICE_TEMPLATE_NAME_FR]: service.nameFr,
      [GtmVariablesEnum.ESTABLISHMENT_SERVICE_TEMPLATE_ID]: service.stId,
      [GtmVariablesEnum.ESTABLISHMENT_SERVICE_TEMPLATE_PRICING_ENABLED]: service.pricingEnabled,
      [GtmVariablesEnum.ESTABLISHMENT_SERVICE_TEMPLATE_NAME_FR]: service.stDescriptionFr,
      [GtmVariablesEnum.ESTABLISHMENT_ID]: place.establishment,
      [GtmVariablesEnum.ESTABLISHMENT_TYPE]: place.establishmentType,
      [GtmVariablesEnum.PLACE_NAME]: place.nameFr,
      [GtmVariablesEnum.PLACE_POSTAL_CODE]: place.postalCode,
      [GtmVariablesEnum.DISTANCE]: place.distance,
      [GtmVariablesEnum.PLACE_REGION]: place.region,
      [GtmVariablesEnum.ESTABLISHMENT_NAME]: place.establishmentName,
      availsNextSevenDays: service.availabilities.t07,
      availsAfterSevenDays: service.availabilities.ta7,
      placeRegion: place.region,
      headOfficeRegionCode: place.headOfficeRegionCode
    })
  }

  const portalVisibilitySearchBarKeywordAnalytics = (keyword, searchNextStep) => {
    gtmPush('portalVisibilitySearchBarKeyword', {
      [GtmVariablesEnum.KEYWORD]: keyword,
      [GtmVariablesEnum.KEYWORD_NEXT_STEP]: searchNextStep
    })
  }

  const portalVisibilityFilterAnalytics = (filterType: string, filterValue: string | null) => {
    gtmPush('portalVisibilityFilter', {
      [GtmVariablesEnum.FILTER_TYPE]: filterType,
      [GtmVariablesEnum.FILTER_VALUE]: filterValue
    })
  }

  const portalVisibilityAllCategoriesFromHeaderAnalytics = () => {
    gtmPush('portalVisibilityAllCategoriesFromHeader')
  }

  const portalVisibilityAllCategoriesFromTilesAnalytics = () => {
    gtmPush('portalVisibilityAllCategoriesFromTiles')
  }

  const portalVisibilityAllCategoriesFromSearchAnalytics = () => {
    gtmPush('portalVisibilityAllCategoriesFromSearch')
  }

  const portalVisibilityAllCategoriesFromMobileMenuAnalytics = () => {
    gtmPush('portalVisibilityAllCategoriesFromMobileMenu')
  }

  return {
    portalVisibilitySearchBarAnalytics,
    portalVisibilitySearchFromServicePageAnalytics,
    portalVisibilitySearchFromIFrameAnalytics,
    portalVisibilityAllCategoriesFromHeaderAnalytics,
    portalVisibilitySelectTileAnalytics,
    portalVisibilityAllCategoriesFromMobileMenuAnalytics,
    portalVisibilityAllCategoriesFromTilesAnalytics,
    portalVisibilityAllCategoriesFromSearchAnalytics,
    portalVisibilitySelectServiceAnalytics,
    portalVisibilitySelectClinicAnalytics,
    portalVisibilitySearchBarKeywordAnalytics,
    portalVisibilityFilterAnalytics
  }
}
