import { MFObservableRole, ObservableWrapperAbstract } from '@trimoz/trimoz-windowed-observable'

export const LS_TRIMOZ_LANG = 'tmz_lang'

/**
 * Observable class for language
 */
class LanguageObservableWrapper extends ObservableWrapperAbstract {
  constructor() {
    super({
      id: 'Language',
      role: MFObservableRole.HOST,
      needsInitialization: true
    })
  }

  //======================
  // Module Federation ON
  //======================

  protected dataHasBeenChangedWithMF(data?: any): void {
    if (data) {
      localStorage.setItem(LS_TRIMOZ_LANG, data)
    } else {
      this.publishWithMF({ data: localStorage.getItem(LS_TRIMOZ_LANG) })
    }
  }

  //======================
  // Module Federation OFF
  //======================

  // Nothing to do
  protected publishWithoutMF(): void {}

  // Nothing to do
  protected subscribeWithoutMF(): void {}
}

// Create and export the observable instance
const languageObservable = new LanguageObservableWrapper()
export default languageObservable
