<script>
import { mapState } from 'pinia'
import { useIframeStore } from '@/stores/iframe'

export default {
  name: 'app',
  computed: {
    ...mapState(useIframeStore, ['getIsIframe'])
  }
}
</script>

<template>
  <router-view />
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

/*
* For a11y. If not present, some focused element have a chance of being behind the bottom menu on a mobile view.
* Note: user partially blind that required intensive zoom (e.g.: 300% zoom) often trigger the mobile environment.
*
* Unfortunately, setting the height value like so break the infinite scroll after searching for a postal code.
* Therefore, we comment it until we have time to find a solution that won't break the scroll and satisfy accessibility demand above.
*/
#app.mobile-app {
  // height: calc(100vh - 80px) !important;
  // overflow-y: auto !important;
}
</style>
