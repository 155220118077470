import get from 'lodash/get'

import { MFObservableRole, ObservableWrapperAbstract } from '@trimoz/trimoz-windowed-observable'

/**
 * Observable class for JWT Token
 */
class TokenObservableWrapper extends ObservableWrapperAbstract {
  constructor() {
    super({
      id: 'Token',
      role: MFObservableRole.HOST
    })
  }

  //======================
  // Module Federation ON
  //======================

  protected dataHasBeenChangedWithMF(data?: any): void {
    const forceRefreshToken = get(data, 'forceRefreshToken', false)
    this._callback(forceRefreshToken)
  }

  //======================
  // Module Federation OFF
  //======================

  protected publishWithoutMF(): void {}

  protected async subscribeWithoutMF(): void {}

  private updateData(token: string | null) {
    if (this._callback && this._callback) {
      this._callback(token)
    }
  }
}

// Create and export the observable instance
const tokenObservableWrapper = new TokenObservableWrapper()
export default tokenObservableWrapper
