import { useGlobalStore } from '@/stores/global'
import { LS_TRIMOZ_LANG } from '@/plugins/mf-event-bus'

export default {
  async created() {
    const globalStore = useGlobalStore()
    const getLanguage = () => {
      const isLocalStorageSupported = Boolean(window.localStorage)
      if (!isLocalStorageSupported) return 'fr' // Return default if localStorage is not supported

      const lang = window.localStorage.getItem(LS_TRIMOZ_LANG)
      if (lang && lang !== 'fr' && lang !== 'en') {
        window.localStorage.setItem(LS_TRIMOZ_LANG, 'fr') // Set default if no valid language is set
        return 'fr'
      }

      return lang // Return the existing language
    }

    await globalStore.setLocale(getLanguage())
  }
}
