import { useGtm } from '@gtm-support/vue-gtm'

export const useGoogleTagManager = () => {
  const gtmPush = (event: string, params: object) => {
    try {
      const gtm = useGtm()
      gtm.trackEvent({ event, ...params })
    } catch (e) {
      console.error('An error occurred while pushing to Google Tag Manager : ' + e)
    }
  }

  return { gtmPush }
}
